<template>
  <div
    class="item-container border-gray border-solid relative h-[100px] w-[auto] px-5 py-5 hover:cursor-pointer bg-white first:border-y last:border-b border-l last:border-r"
  >
    <nuxt-link :to="localePath('/brand/' + brand?.slug)">
      <ListBrandsTaxon :taxon="brand.taxon" />
      <lazy-nuxt-img
        :src="
          imageError
            ? NO_IMAGE_PATH
            : brand.getImagePath(PRODUCT_IMAGE_FILTER.BANNER_TINY)
        "
        loading="lazy"
        class="object-scale-down h-[60px] m-auto"
        height="60"
        @error="() => (imageError = true)"
      />
    </nuxt-link>
    <!-- <div class="swiper-lazy-preloader"></div> -->
  </div>
</template>
<script setup>
defineProps({
  brand: {
    type: Object,
    default: () => {}
  }
})

const imageError = ref(false)
</script>

<style lang="postcss" scoped>
.item-container:hover {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
  background: white;
}
</style>

<template>
  <div
    v-if="data"
    class="px-1.5 h-[19px] absolute top-0 left-0 text-white text-[10px] flex items-center"
    :class="[data.code !== 'ibis' ? 'bg-orange' : 'bg-green-ibis']"
  >
    {{ data.name }}
  </div>
</template>
<script setup>
const props = defineProps({
  taxon: {
    type: String,
    default: ''
  }
})
const { data } = useLazyAsyncData(
  'shop-taxons-' + props.taxon,
  () => props.taxon && useAsyncResources(props.taxon)
)
</script>
